import { createApp } from "vue";
import FloatingCart from "./FloatingCart.vue";
import EventEmitterMixin from 'frappe/public/js/frappe/event_emitter';
import { ioi_format_currency } from "../cart/format_currency";

frappe.provide("ioi_partners.floating_shopping_cart_update")

window.ioi_format_currency = ioi_format_currency;

ioi_partners.floating_shopping_cart_update = {}

ioi_partners.floating_shopping_cart = () => {
	Object.assign(ioi_partners.floating_shopping_cart_update, EventEmitterMixin);

	const app = createApp(FloatingCart, {});
	window.SetVueGlobals(app)
	app.provide("ioi_format_currency", ioi_format_currency);
	app.mount("#floating-cart");
}