<template id="buy-box">
	<tr>
		<td scope="row">
			<h6>{{ item.product_id }}</h6>
			<div v-if="best_max_user_not_achieved" class="text-danger small">
				<div>{{ __("This product may be unsuitable for the number of user defined.") }}</div>
				<div>{{ __("Please check the product description.") }}</div>
			</div>
			<p v-if="item.estimated_setup_time">{{ __("Est. setup time*") }}: <span>{{ item.estimated_setup_time }} {{ __("H") }}</span></p>
		</td>
		<td>
			<div class="flex">
				<div class="qty-minus" :class="[item_can_be_updated() ? 'grey-btn' : '']" @click="$emit('minusQty', item)"><span
						v-if="item_can_be_updated()">-</span></div>
				<div class="qty">{{ item_qty }} {{ __(item_unit) }}</div>
				<div class="qty-plus" :class="[item_can_be_updated() ? 'grey-btn' : '']" @click="$emit('plusQty', item)"><span
						v-if="item_can_be_updated()">+</span></div>
				<div class="del" :class="[item_can_be_removed() ? 'grey-btn' : '']" @click="$emit('removeItem', item)">
					<svg class="icon sm remove-cart-item-logo" v-if="item_can_be_removed()" width="18" height="18"
						viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" id="icon-close">
						<path fill-rule="evenodd" clip-rule="evenodd"
							d="M4.146 11.217a.5.5 0 1 0 .708.708l3.182-3.182 3.181 3.182a.5.5 0 1 0 .708-.708l-3.182-3.18 3.182-3.182a.5.5 0 1 0-.708-.708l-3.18 3.181-3.183-3.182a.5.5 0 0 0-.708.708l3.182 3.182-3.182 3.181z"
							stroke-width="0"></path>
					</svg>
				</div>
			</div>
		</td>
		<td>
			<p>{{ frappe.datetime.obj_to_user(item.start_date) }}</p>
		</td>
		<td>
			<p>{{ __(renewal_date) }}</p>
		</td>
		<td>
			<p :class="is_free_limit_expired ? 'linethrough' : ''">{{ __(eval_end_date) }}</p>
		</td>
		<td>
			<p>{{ ioi_format_currency(install_cost || 0.0, item.currency) }}</p>
		</td>
		<td>
			<p>{{ ioi_format_currency(enduser_price || 0.0, item.currency) }}</p>
		</td>
		<td class="text-right">
			<span :class="is_eval ? 'linethrough' : ''">{{ ioi_format_currency(item_total || 0.0, item.currency) }}</span>
		</td>
	</tr>
</template>


<script setup>
import { ioi_format_currency } from "./format_currency";
import { computed} from 'vue'

const props = defineProps({
	item: {
		type: Object,
		default: () => { return {} }
	},
	users: {
		type: Number,
		default: 0
	}
})

const item_qty = computed(() => {
	if (["Inactive", "Active", "Locked"].includes(props.item.status)) {
		return props.item.active_qty
	} else if (props.item.status == "Under Process") {
		return props.item.qty_ordered
	} else if (props.item.status == "In Basket") {
		return props.item.qty_in_basket
	}
})

const item_total= computed(() => {
	if (["One of", "Credit"].includes(props.item.unit_of_measure)) {
		return 0.0
	}

	return subscription_total.value
})

const subscription_total = computed(() => {
	if (["Inactive", "Active", "Locked"].includes(props.item.status)) {
		return props.item.active_qty * props.item.enduser_price
	} else if (props.item.status == "Under Process") {
		return props.item.qty_ordered * props.item.enduser_price
	} else if (props.item.status == "In Basket") {
		return props.item.qty_in_basket * props.item.enduser_price
	}
})

const item_unit = computed(() => {
	return ["One of", "Credit"].includes(props.item.unit_of_measure) ? "Unit" : props.item.unit_of_measure
})

const is_eval = computed(() => {
	return props.item.status == "Under Process" && props.item.free_limit_date
})

const is_free_limit_expired = computed(() => {
	return props.item.free_limit_date <= frappe.datetime.now_date()
})

const enduser_price = computed(() => {
	return ["One of", "Credit"].includes(props.item.unit_of_measure) ? 0.0 : props.item.enduser_price
})

const install_cost = computed(() => {
	return ["One of", "Credit"].includes(props.item.unit_of_measure) ? subscription_total.value + flt(props.item.install_cost) : props.item.install_cost
})

const renewal_date = computed(() => {
	if (props.item.free_limit_date) {
		return props.item.free_limit_date <= frappe.datetime.now_date() ? "Trial Expired" : "Trial"
	} else if (props.item.renewal_date) {
		return frappe.datetime.obj_to_user(props.item.renewal_date)
	} else {
		return "Not renewable"
	}
})

const eval_end_date = computed(() => {
	if (props.item.free_limit_date) {
		return frappe.datetime.obj_to_user(props.item.free_limit_date)
	} else if (!props.item.eval) {
		if (props.item.eval_replaced_by) {
			return "Changed if eval"
		} else {
			return "Not available in eval"
		}
	}
})

const best_max_user_not_achieved = computed(() => {
	if (!props.item.best_max_user) {
		return false
	}

	return props.item.best_max_user > props.users
})

function item_can_be_updated() {
	return (
		(props.item.status == 'In Basket' || ['Active', 'Inactive'].includes(props.item.status))
		&& ["User", "Credit"].includes(props.item.unit_of_measure)
	)
}

function item_can_be_removed() {
	return props.item.status == 'In Basket'
}

</script>

<style scoped lang="scss">
img {
	height: 100px;
	float: left;
}

h4 {
	text-align: center;
}

p {
	text-align: center;
}


.qty-minus {
	margin-left: auto;
	cursor: pointer;
	padding: 0 1rem;
	border: none;
	box-shadow: none;
	width: 24px;
	height: 28px;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 20px;
	font-weight: 300;
	color: var(--gray-700);
	border-radius: 0.5rem;

	&.grey-btn {
		background: var(--gray-100);
	}
}

.qty {
	text-align: center;
	margin: 0 0.5rem;
}

.qty-plus {
	text-align: center;
	cursor: pointer;
	padding: 0 1rem;
	border: none;
	box-shadow: none;
	width: 24px;
	height: 28px;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 20px;
	font-weight: 300;
	color: var(--gray-700);
	border-radius: 0.5rem;

	&.grey-btn {
		background: var(--gray-100);
	}
}

.del {
	cursor: pointer;
	margin-right: auto;
	margin-left: 0.5rem;
	padding: 0 1rem;
	border: none;
	box-shadow: none;
	height: 28px;
	align-items: center;
	justify-content: center;
	display: flex;
	font-size: 20px;
	font-weight: 300;
	color: var(--gray-700);
	border-radius: 0.5rem;

	&.grey-btn {
		background: var(--gray-100);
	}
}

.linethrough {
	text-decoration: line-through;;
}
</style>