<template>
	<tr class="total">
		<td colspan="3" class="small text-muted" v-if="display_footer" v-html="footer_text"></td>
		<td :colspan="display_footer ? '3' : '6' " class="text-right">
			<h6 v-if="carttype == 'shopping-cart'">{{ __("Total Setup and Purchase") }}</h6>
			<h6 v-if="carttype == 'shopping-cart'">{{ __("Total Subscription") }}</h6>
			<h6 v-if="carttype == 'shopping-cart'">{{ __("Setup time*") }}</h6>
			<h5 v-else>{{ __(total_text) }}</h5>
		</td>
		<td colspan="3" class="text-right">
			<h6 v-if="carttype == 'shopping-cart'">{{ ioi_format_currency(subscription_object.setup_total_in_basket || 0.0, subscription_object.currency) }}</h6>
			<h6 v-if="carttype == 'shopping-cart'">{{ ioi_format_currency(subscription_object.subscription_total_in_basket || 0.0, subscription_object.currency) }}</h6>
			<h6 v-if="carttype == 'shopping-cart'">{{ subscription_object.setup_time_in_basket }} {{ __("Hours") }}</h6>
			<h5 v-else>{{ total_amount }}</h5>
		</td>
	</tr>
</template>

<script setup>
import { ioi_format_currency } from "./format_currency";

import { computed } from 'vue'


const props = defineProps({
	subscription_object: {
		type: Object,
		default: () => { return {} }
	},
	carttype: {
		type: String,
		default: "subscription"
	}
})

const total_text = computed(() => {
	if (props.carttype == 'subscription') {
		return "Total active:"
	} else if (props.carttype == 'order') {
		return "Basket value:"
	} else if (props.carttype == 'shopping-cart') {
		return "Total in basket:"
	}
})

const total_amount = computed(() => {
	if (props.carttype == 'subscription') {
		return ioi_format_currency(props.subscription_object.total_active || 0.0, props.subscription_object.currency)
	} else if (props.carttype == 'order') {
		return ioi_format_currency(props.subscription_object.total_ordered || 0.0, props.subscription_object.currency)
	} else if (props.carttype == 'shopping-cart') {
		return ioi_format_currency(props.subscription_object.total_in_basket || 0.0, props.subscription_object.currency)
	}	
})

const display_footer = computed(() =>  {
	if (props.carttype == 'shopping-cart' && props.subscription_object.subscription_items.filter(f => f.estimated_setup_time > 0)) {
		return true
	} else {
		return false
	}
})

const footer_text = computed(() => {
	let footer = ""
	switch(frappe.boot.user_language) {
		case 'fr':
			footer = `
				<div>*Le temps d’installation est ici indicatif mais est une estimation pour une mise en place standard simple par votre VAR Expert choisi.</div>
				<div>Dans cette commande, vous ne vous engagez pas pour cette partie.</div>
				<div>Un audit de votre activité, une analyse flux, un cahier des charges permettent d’ajuster ce temps indicatif.</div>
				<div>(S’attendre à des variations de temps importantes selon les demandes ou besoins.)</div>
				<div>Votre VAR Expert choisi vous proposera un plan de formation, ici non inclus, en fonction des équipes et des fonctions.</div>
				<div>Des rapports standard sont fournis mais doivent en général être adaptés. Ce temps n’est pas compté dans le temps de mise en place.</div>
				<div>Si il y a des modules offerts gracieusement ou inclus, ils nécessitent aussi un temps d’installation et de formation.</div>
			`
			break;

		case 'en':
		case 'nl':
		case 'de':
		footer = `
				<div>*The installation time is indicative but is an estimate for a simple standard installation by your chosen VAR Expert.</div>
				<div>In this order, you do not commit to this non valued part.</div>
				<div>An audit of your activity, a flow analysis, and a set of specifications will allow you to adjust this indicative time.</div>
				<div>(Expect significant variations in time depending on the requests or needs).</div>
				<div>Your chosen VAR Expert will propose a training plan, here not included, according to the teams and functions.</div>
				<div>Standard reports are provided but usually need to be adapted. This time is not counted in the implementation time.</div>
				<div>If there are modules offered for free or included, they also require installation and training time.</div>
			`
			break;
	}
	return footer;
})

</script>

<style scoped>
.total {
	color: #777777;
	font-weight: 600;
	font-size: 13px;
	color: var(--text-muted);
	padding: var(--padding-sm) 0;
}

</style>