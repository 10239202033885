import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2d65a175"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "total" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["colspan"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = {
  colspan: "3",
  class: "text-right"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { key: 2 }
const _hoisted_12 = { key: 3 }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    ($setup.display_footer)
      ? (_openBlock(), _createElementBlock("td", {
          key: 0,
          colspan: "3",
          class: "small text-muted",
          innerHTML: $setup.footer_text
        }, null, 8 /* PROPS */, _hoisted_2))
      : _createCommentVNode("v-if", true),
    _createElementVNode("td", {
      colspan: $setup.display_footer ? '3' : '6' ,
      class: "text-right"
    }, [
      ($props.carttype == 'shopping-cart')
        ? (_openBlock(), _createElementBlock("h6", _hoisted_4, _toDisplayString(_ctx.__("Total Setup and Purchase")), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($props.carttype == 'shopping-cart')
        ? (_openBlock(), _createElementBlock("h6", _hoisted_5, _toDisplayString(_ctx.__("Total Subscription")), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($props.carttype == 'shopping-cart')
        ? (_openBlock(), _createElementBlock("h6", _hoisted_6, _toDisplayString(_ctx.__("Setup time*")), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("h5", _hoisted_7, _toDisplayString(_ctx.__($setup.total_text)), 1 /* TEXT */))
    ], 8 /* PROPS */, _hoisted_3),
    _createElementVNode("td", _hoisted_8, [
      ($props.carttype == 'shopping-cart')
        ? (_openBlock(), _createElementBlock("h6", _hoisted_9, _toDisplayString($setup.ioi_format_currency($props.subscription_object.setup_total_in_basket || 0.0, $props.subscription_object.currency)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($props.carttype == 'shopping-cart')
        ? (_openBlock(), _createElementBlock("h6", _hoisted_10, _toDisplayString($setup.ioi_format_currency($props.subscription_object.subscription_total_in_basket || 0.0, $props.subscription_object.currency)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      ($props.carttype == 'shopping-cart')
        ? (_openBlock(), _createElementBlock("h6", _hoisted_11, _toDisplayString($props.subscription_object.setup_time_in_basket) + " " + _toDisplayString(_ctx.__("Hours")), 1 /* TEXT */))
        : (_openBlock(), _createElementBlock("h5", _hoisted_12, _toDisplayString($setup.total_amount), 1 /* TEXT */))
    ])
  ]))
}