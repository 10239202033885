import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0a792145"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cart-categories" }
const _hoisted_2 = { colspan: "8" }
const _hoisted_3 = {
  key: 0,
  class: "category"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, [
      ($props.category!='null')
        ? (_openBlock(), _createElementBlock("h5", _hoisted_3, _toDisplayString(_ctx.__($props.category)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}