<template>
	<tr class="cart-categories" >
		<td colspan="8">
			<h5 class="category" v-if="category!='null'">{{ __(category) }}</h5>
		</td>
	</tr>
</template>

<script setup>

const props = defineProps({
	category: {
		type: String,
		default: () => null
	},
})
</script>

<style scoped style="scss">
.cart-categories {
	width: 100%;
	margin-bottom: 1rem;
	color: #4c5a67;
}

.cart-categories > td {
	border-top: none;
}

.category {
	background-color: var(--primary);
	color: var(--white);
	white-space: nowrap;
	--icon-stroke: currentColor;
	--icon-fill-bg: var(--primary);
	border-radius: 4px;
	padding: 0.2rem;
}

</style>