<template>
	<div id="floating-cart" class="frappe-card mb-4" v-show="Object.keys(cart).length && cart.subscription_items.length">
		<h6 class="title">{{ __("Your selection") }}</h6>
		<div class="card h-100">
			<div class="card-body p-0">
				<table class="w-100">
					<tbody>
						<tr v-for="item in cart.subscription_items" :key="item.name" class="cart-items">
							<td class="bill-label">{{ item.product_id}} x {{ item.qty_in_basket }}</td>
							<td class="bill-content net-total text-right">{{ ioi_format_currency(item.total_amount || 0.0, item.currency) }}</td>
						</tr>
					</tbody>
				</table>
				<table class="w-100 grand-total mt-4">
					<tbody>
						<tr>
							<td class="bill-content sub-total">{{ __("Subscription") }}</td>
							<td class="bill-content sub-total text-right">{{ ioi_format_currency(cart.subscription_total_in_basket || 0.0, cart.currency) }}*</td>
						</tr>
						<tr>
							<td class="bill-content sub-total">{{ __("Setup and Purchase") }}</td>
							<td class="bill-content sub-total text-right">{{ ioi_format_currency(cart.setup_total_in_basket || 0.0, cart.currency) }}</td>
						</tr>
						<tr>
							<td class="bill-content sub-total">
								<span>{{ __("Setup time*") }}</span>
								<p class="small muted">{{ __("*Estimated configuration time by your reseller") }}</p>
							</td>
							<td class="bill-content sub-total text-right">{{ cart.setup_time_in_basket }} {{ __("Hours") }}</td>
						</tr>
					</tbody>
				</table>
				<a class="btn btn-primary btn-place-order font-md w-100 mt-4" type="button" href="/subscription">
					{{ __("See selection") }}
				</a>
				<p class="text-muted small">{{ __("*monthly year based or fixed price see details") }}</p>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ioi_format_currency } from "../cart/format_currency";
import { ref, onMounted} from 'vue'

const cart = ref({})

onMounted(() => {
	ioi_partners.floating_shopping_cart_update.on("update_subscription", () => {
		get_subscription()
	})
})

function get_subscription() {
	frappe.call({
		method: "ioi_partners.www.subscription.get_subscription"
	}).then(r => {
		if (Object.keys(r.message).length) {
			const items = r.message.subscription_items
			cart.value = Object.assign({}, r.message, {subscription_items: items.filter(f => f.qty_in_basket > 0)});
		}
	})
}

</script>

<style lang="scss">
#floating-cart {
	.title {
		font-size: 0.9rem;
		font-weight: 600;
	}

	.grand-total {
		font-weight: 600;

		.sub-total {
			font-size: 0.8rem;
		}

		th, td {
			border-top: 1px solid #E2E6E9;
			padding-top: 0.4rem;
		}
	}

	.cart-items {
		font-size: 0.8rem;
	}
}
</style>