import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  id: "floating-cart",
  class: "frappe-card mb-4"
}
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "card h-100" }
const _hoisted_4 = { class: "card-body p-0" }
const _hoisted_5 = { class: "w-100" }
const _hoisted_6 = { class: "bill-label" }
const _hoisted_7 = { class: "bill-content net-total text-right" }
const _hoisted_8 = { class: "w-100 grand-total mt-4" }
const _hoisted_9 = { class: "bill-content sub-total" }
const _hoisted_10 = { class: "bill-content sub-total text-right" }
const _hoisted_11 = { class: "bill-content sub-total" }
const _hoisted_12 = { class: "bill-content sub-total text-right" }
const _hoisted_13 = { class: "bill-content sub-total" }
const _hoisted_14 = { class: "small muted" }
const _hoisted_15 = { class: "bill-content sub-total text-right" }
const _hoisted_16 = {
  class: "btn btn-primary btn-place-order font-md w-100 mt-4",
  type: "button",
  href: "/subscription"
}
const _hoisted_17 = { class: "text-muted small" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h6", _hoisted_2, _toDisplayString(_ctx.__("Your selection")), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("table", _hoisted_5, [
          _createElementVNode("tbody", null, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.cart.subscription_items, (item) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: item.name,
                class: "cart-items"
              }, [
                _createElementVNode("td", _hoisted_6, _toDisplayString(item.product_id) + " x " + _toDisplayString(item.qty_in_basket), 1 /* TEXT */),
                _createElementVNode("td", _hoisted_7, _toDisplayString($setup.ioi_format_currency(item.total_amount || 0.0, item.currency)), 1 /* TEXT */)
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]),
        _createElementVNode("table", _hoisted_8, [
          _createElementVNode("tbody", null, [
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.__("Subscription")), 1 /* TEXT */),
              _createElementVNode("td", _hoisted_10, _toDisplayString($setup.ioi_format_currency($setup.cart.subscription_total_in_basket || 0.0, $setup.cart.currency)) + "*", 1 /* TEXT */)
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.__("Setup and Purchase")), 1 /* TEXT */),
              _createElementVNode("td", _hoisted_12, _toDisplayString($setup.ioi_format_currency($setup.cart.setup_total_in_basket || 0.0, $setup.cart.currency)), 1 /* TEXT */)
            ]),
            _createElementVNode("tr", null, [
              _createElementVNode("td", _hoisted_13, [
                _createElementVNode("span", null, _toDisplayString(_ctx.__("Setup time*")), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.__("*Estimated configuration time by your reseller")), 1 /* TEXT */)
              ]),
              _createElementVNode("td", _hoisted_15, _toDisplayString($setup.cart.setup_time_in_basket) + " " + _toDisplayString(_ctx.__("Hours")), 1 /* TEXT */)
            ])
          ])
        ]),
        _createElementVNode("a", _hoisted_16, _toDisplayString(_ctx.__("See selection")), 1 /* TEXT */),
        _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.__("*monthly year based or fixed price see details")), 1 /* TEXT */)
      ])
    ])
  ], 512 /* NEED_PATCH */)), [
    [_vShow, Object.keys($setup.cart).length && $setup.cart.subscription_items.length]
  ])
}