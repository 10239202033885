<template>
	<tr class="cart-validation" v-if="items_in_cart.length">
		<td colspan="4">
			<div v-if="!confirmation_requested" class="validation-text">{{ __("Validate your selection") }}</div>
			<div class="small text-muted">{{ __("Evaluation is free, no payment required") }}</div>
		</td>
		<td colspan="4">
			<div>
				<div class="text-right">
					<button class="btn btn-primary mr-2" @click="ask_for_trial_confirmation" v-if="!confirmation_requested">{{ __("For 15 days trial period") }}</button>
					<button class="btn btn-primary" @click="ask_for_confirmation" v-if="!confirmation_requested">{{ __("To order") }}</button>
				</div>
				<div class="form-group checkbox" v-if="confirmation_requested && !subscription_object.vat_number && !trial">
					<label class="form-label" for="vat_number">{{ __("Your company's VAT Number") }}</label>
					<input type="text" id="vat_number" class="form-control" placeholder="BE1234567890" v-model="vat_number" required>
				</div>
				<div class="form-group checkbox"  v-if="confirmation_requested && !trial">
					<label class="form-label" for="accept_terms">
						<span class="input-area">
							<input type="checkbox" id="accept_terms" class="form-control" v-model="accept_terms">
						</span>
						<span class="label-area"><a :href="terms_url" target="_blank">{{ __("I have read the Term of use and sales before this order") }}</a></span>
					</label>
				</div>
				<div class="form-group checkbox"  v-if="confirmation_requested && !trial">
					<label class="form-label" for="accept_var_terms">
						<span class="input-area">
							<input type="checkbox" id="accept_var_terms" class="form-control" v-model="accept_var_terms">
						</span>
						<span class="label-area"><a :href="var_terms_url" target="_blank">{{ __("I have read and I accept the VAR Expert Sales condition") }}</a></span>
					</label>
				</div>
				<div class="form-group checkbox" v-if="confirmation_requested && !trial">
					<label class="form-label" for="cart_clearance">
						<span class="input-area">
							<input type="checkbox" id="cart_clearance" class="form-control" v-model="cart_clearance">
						</span>
						<span class="label-area">{{ __("I am a person authorized to commit the company for this type of order") }}</span>
					</label>
				</div>
				<div class="form-group checkbox" v-if="confirmation_requested && trial">
					<label class="form-label" for="trial_terms">
						<span class="input-area">
							<input type="checkbox" id="trial_terms" class="form-control" v-model="trial_terms">
						</span>
						<span class="label-area">{{ __("I have read and by checking this box I accept the terms of use of the ioi software range") }}</span>
					</label>
					<div class="small text-muted">{{ __("Products not available in evaluation will be deleted or replaced") }}</div>
				</div>
				<div class="text-right">
					<button class="btn btn-danger text-right" @click="validate_cart(vat_number, trial)" v-if="!trial && confirmation_requested && (subscription_object.vat_number || vat_number) && cart_clearance && accept_terms" :disabled="confirmation_clicked">{{ __("Confirm your order") }}</button>
					<button class="btn btn-danger text-right" @click="validate_cart(vat_number, trial)" v-if="trial && confirmation_requested && trial_terms" :disabled="confirmation_clicked">{{ __("Confirm your trial request") }}</button>
				</div>
			</div>
		</td>

	</tr>
</template>

<script setup>

import { ref, computed } from 'vue'

const emit = defineEmits(['validate_cart'])

const props = defineProps({
	subscription_object: {
		type: Object,
		default: () => { return {} }
	}
})

const confirmation_requested = ref(false)
const cart_clearance = ref(false)
const accept_terms = ref(false)
const trial_terms = ref(false)
const accept_var_terms = ref(false)
const vat_number = ref("")
const trial = ref(false)
const confirmation_clicked = ref(false)


const items_in_cart = computed(() => {
	if (props.subscription_object.subscription_items) {
		return props.subscription_object.subscription_items.filter(f => f.status == "In Basket")
	} else {
		return []
	}
})

const terms_url = computed(() => {
	const lang = frappe.boot.user_language.toUpperCase()
	return `/assets/ioi_partners/terms/Silicon ioi Software Term of use ${lang}.pdf`
})

const var_terms_url = computed(() => {
	const lang = frappe.boot.user_language.toLowerCase()
	return `/assets/ioi_partners/terms/${frappe.scrub(props.subscription_object.var_id)}_${lang}.pdf`
})

function ask_for_confirmation() {
	confirmation_requested.value = true
}

function ask_for_trial_confirmation() {
	trial.value = true;
	ask_for_confirmation()
}

function validate_cart(vat_number, trial) {
	confirmation_clicked.value = true;
	emit('validate_cart', vat_number, trial)
}
</script>

<style scoped>
.cart-validation {
	margin-top: 2rem;
}
</style>