<template>
	<div id="shopping-cart">
		<div v-if="Object.keys(cart).length && cart.subscription_items.length">
			<div class="shopping-cart-title"><h2>{{ __(selection_title) }}</h2></div>
			<div>
				<shopping-cart :subscription="cart" carttype="shopping-cart" @get_subscription="get_subscription()"></shopping-cart>
			</div>
		</div>
		<div v-if="Object.keys(order).length && order.subscription_items.length">
			<div class="shopping-cart-title"><h2>{{ __(waiting_request) }}</h2></div>
			<div>
				<shopping-cart :subscription="order" carttype="order" @get_subscription="get_subscription()"></shopping-cart>
			</div>
		</div>
		<div v-if="Object.keys(subscription).length && subscription.subscription_items.length">
			<div class="shopping-cart-title">
				<h2>{{ __(active_subscription) }}</h2>
			</div>
			<div>
				<shopping-cart :subscription="subscription" carttype="subscription" @get_subscription="get_subscription()"></shopping-cart>
			</div>
		</div>
		<div v-if="!Object.keys(subscription).length || (!subscription.subscription_items.length && !cart.subscription_items.length && !order.subscription_items.length)">
			<div class="shopping-cart-title">
				<h2>{{ __("Select a product to create a subscription") }}</h2>
			</div>
		</div>
	</div>
</template>


<script setup>
import ShoppingCart from './Cart.vue';

import { ref, onMounted} from 'vue'

const props = defineProps({
	subscription_name: {
		type: String,
		default: ""
	}
})

const items = ref([])
const subscription = ref({})
const cart = ref({})
const order = ref({})
const selection_title = ref(props.subscription_name ? "Selection" : "Your selection")
const waiting_request = ref(props.subscription_name ? "Waiting Request" : "Your waiting request")
const active_subscription = ref(props.subscription_name ? "Active Subscription" : "Your active subscription")


function get_var_products() {
	frappe.call({
		method: "ioi_partners.www.subscription.get_products"
	}).then(r => {
		items.value = r.message;
	})
}

function get_subscription() {
	frappe.call({
		method: "ioi_partners.www.subscription.get_subscription",
		args: {
			subscription_name: props.subscription_name
		}
	}).then(r => {
		if (Object.keys(r.message).length) {
			items.value = r.message.subscription_items
			subscription.value = Object.assign({}, r.message, {subscription_items: items.value.filter(f => f.active_qty > 0 && f.status != "Archive")});
			cart.value = Object.assign({}, r.message, {subscription_items: items.value.filter(f => f.qty_in_basket > 0)});
			order.value = Object.assign({}, r.message, {subscription_items: items.value.filter(f => f.qty_ordered > 0)});
		}
	})
}

onMounted(() => {
	get_var_products()
	get_subscription()
})

</script>

<style scoped lang="scss">
#shopping-cart {
	margin: 1.5rem 0;

	.shopping-cart-title {
		h2 {
			font-size: 1.2rem;
		}
	}
}
</style>