import { createApp } from "vue";
import CartPage from "./CartPage.vue";
import { ioi_format_currency } from "./format_currency";

frappe.provide("ioi_partners")

window.ioi_format_currency = ioi_format_currency;

ioi_partners.shopping_cart = (subscription) => {
	const app = createApp(CartPage, {
		subscription_name: subscription
	});
	window.SetVueGlobals(app)
	app.provide("ioi_format_currency", ioi_format_currency);
	app.mount("#cart");
}

$("body").find(".container").addClass("container-fluid").removeClass("container")