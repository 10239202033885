<template>
	<div id="cart" class="frappe-card">
		<div v-if="subscription.subscription_items.length" class="table-responsive">
			<table class="table">
				<thead>
					<tr>
						<th scope="col"></th>
						<th id="quantity" scope="col">
							<h5>{{ __("Quantity") }}</h5>
						</th>
						<th id="start-date" scope="col">
							<h5>{{ __("Start Date") }}</h5>
						</th>
						<th id="renewal-date" scope="col">
							<h5>{{ __("Renewal Date") }}</h5>
						</th>
						<th id="free-limit-date" scope="col">
							<h5>{{ __("Free Limit Date") }}</h5>
						</th>
						<th id="price" scope="col">
							<h5>{{ __("Setup and Purchase") }}</h5>
						</th>
						<th id="price" scope="col">
							<h5>{{ __("Unit Subscription") }}</h5>
						</th>
						<th id="total" scope="col">
							<h5>{{ __("Total Subscription") }}</h5>
						</th>
					</tr>
				</thead>
				<tbody>
					<template v-for="category in Object.keys(items_by_category)">
						<cart-categories :category="category" :key="category" v-if="items_by_category[category].length"></cart-categories>
						<cart-item
							v-for="item in items_by_category[category]"
							:key="item.name"
							:item="item"
							:users="number_of_users"
							@minus-qty="(i) => minusQty(i)"
							@plus-qty="(i) => plusQty(i)"
							@remove-item="(i) => removeItem(i)"
						/>
					</template>
					
					<cart-total :subscription_object="subscription" :carttype="carttype"></cart-total>
					<cart-validation :subscription_object="subscription" v-if="carttype=='shopping-cart'" @validate_cart="(vat_number, trial) => validate_cart(vat_number, trial)"></cart-validation>
				</tbody>
			</table>
		</div>
		<div v-else-if="carttype=='shopping-cart'">
			<div class="empty-cart-message">
				<h5>{{ __("Your shopping cart is empty") }}</h5>
			</div>
		</div>
		<div v-else>
			<div class="empty-cart-message">
				<h5>{{ __("Select a product to start a new subscription") }}</h5>
			</div>
		</div>
	</div>
</template>

<script setup>
import CartTotal from './CartTotal.vue';
import CartCategories from "./CartCategories.vue";
import CartValidation from "./CartValidation.vue";
import CartItem from "./CartItem.vue";

import { computed } from 'vue'

const emit = defineEmits(['get_subscription'])

const props = defineProps({
	subscription: {
		type: Object,
		default: {}
	},
	carttype: {
		type: String,
		default: "subscription"
	}
})

const items_by_category = computed(() => {
	if (props.subscription.subscription_items) {
		return props.subscription.subscription_items.reduce((groups, item) => {
			const group = (groups[item.product_family] || []);
			group.push(item);
			groups[item.product_family] = group;
			return groups;
		}, {});
	} else {
		return {}
	}
})

const number_of_users = computed(() => {
	return props.subscription.subscription_items.reduce((count, item) => {
		if (item.unit_of_measure == "User") {
			count += item.qty_in_basket + item.qty_ordered + item.active_qty
		}
		return count
	}, 0)
})

function update_subscription(line_id, qty) {
	frappe.call({
		method: "ioi_partners.www.subscription.update_subscription",
		args: {
			line_id: line_id,
			qty: qty
		}
	}).then(r => {
		emit('get_subscription')
	})
}

function validate_cart(vat_number, trial) {
	frappe.call({
		method: "ioi_partners.www.subscription.validate_cart",
		args: {
			vat_number: vat_number,
			trial: trial
		}
	}).then(r => {
		emit('get_subscription')
		window.location = `/confirmation?trial=${trial ? 1 : 0}&var=${props.subscription.var_id}`
	})
}

function removeItem (item) {
	update_subscription(item.name, 0.0)
}

function plusQty (item) {
	update_subscription(item.name, item.qty_in_basket + 1)
}

function minusQty (item) {
	update_subscription(item.name, item.qty_in_basket - 1)
}
</script>

<style lang="scss">
thead {
	& th > h5 {
		color: #777777;
		font-weight: 400;
		font-size: 13px;
		color: var(--text-muted);
		padding: var(--padding-sm) 0;
	}
}

#quantity {
	padding-right: 4.5rem;
	text-align: center;
}

</style>