import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-610024f7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cart-validation"
}
const _hoisted_2 = { colspan: "4" }
const _hoisted_3 = {
  key: 0,
  class: "validation-text"
}
const _hoisted_4 = { class: "small text-muted" }
const _hoisted_5 = { colspan: "4" }
const _hoisted_6 = { class: "text-right" }
const _hoisted_7 = {
  key: 0,
  class: "form-group checkbox"
}
const _hoisted_8 = {
  class: "form-label",
  for: "vat_number"
}
const _hoisted_9 = {
  key: 1,
  class: "form-group checkbox"
}
const _hoisted_10 = {
  class: "form-label",
  for: "accept_terms"
}
const _hoisted_11 = { class: "input-area" }
const _hoisted_12 = { class: "label-area" }
const _hoisted_13 = ["href"]
const _hoisted_14 = {
  key: 2,
  class: "form-group checkbox"
}
const _hoisted_15 = {
  class: "form-label",
  for: "accept_var_terms"
}
const _hoisted_16 = { class: "input-area" }
const _hoisted_17 = { class: "label-area" }
const _hoisted_18 = ["href"]
const _hoisted_19 = {
  key: 3,
  class: "form-group checkbox"
}
const _hoisted_20 = {
  class: "form-label",
  for: "cart_clearance"
}
const _hoisted_21 = { class: "input-area" }
const _hoisted_22 = { class: "label-area" }
const _hoisted_23 = {
  key: 4,
  class: "form-group checkbox"
}
const _hoisted_24 = {
  class: "form-label",
  for: "trial_terms"
}
const _hoisted_25 = { class: "input-area" }
const _hoisted_26 = { class: "label-area" }
const _hoisted_27 = { class: "small text-muted" }
const _hoisted_28 = { class: "text-right" }
const _hoisted_29 = ["disabled"]
const _hoisted_30 = ["disabled"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return ($setup.items_in_cart.length)
    ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createElementVNode("td", _hoisted_2, [
          (!$setup.confirmation_requested)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.__("Validate your selection")), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.__("Evaluation is free, no payment required")), 1 /* TEXT */)
        ]),
        _createElementVNode("td", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              (!$setup.confirmation_requested)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn btn-primary mr-2",
                    onClick: $setup.ask_for_trial_confirmation
                  }, _toDisplayString(_ctx.__("For 15 days trial period")), 1 /* TEXT */))
                : _createCommentVNode("v-if", true),
              (!$setup.confirmation_requested)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn btn-primary",
                    onClick: $setup.ask_for_confirmation
                  }, _toDisplayString(_ctx.__("To order")), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            ($setup.confirmation_requested && !$props.subscription_object.vat_number && !$setup.trial)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.__("Your company's VAT Number")), 1 /* TEXT */),
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    id: "vat_number",
                    class: "form-control",
                    placeholder: "BE1234567890",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.vat_number) = $event)),
                    required: ""
                  }, null, 512 /* NEED_PATCH */), [
                    [_vModelText, $setup.vat_number]
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            ($setup.confirmation_requested && !$setup.trial)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("label", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        id: "accept_terms",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.accept_terms) = $event))
                      }, null, 512 /* NEED_PATCH */), [
                        [_vModelCheckbox, $setup.accept_terms]
                      ])
                    ]),
                    _createElementVNode("span", _hoisted_12, [
                      _createElementVNode("a", {
                        href: $setup.terms_url,
                        target: "_blank"
                      }, _toDisplayString(_ctx.__("I have read the Term of use and sales before this order")), 9 /* TEXT, PROPS */, _hoisted_13)
                    ])
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            ($setup.confirmation_requested && !$setup.trial)
              ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                  _createElementVNode("label", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        id: "accept_var_terms",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.accept_var_terms) = $event))
                      }, null, 512 /* NEED_PATCH */), [
                        [_vModelCheckbox, $setup.accept_var_terms]
                      ])
                    ]),
                    _createElementVNode("span", _hoisted_17, [
                      _createElementVNode("a", {
                        href: $setup.var_terms_url,
                        target: "_blank"
                      }, _toDisplayString(_ctx.__("I have read and I accept the VAR Expert Sales condition")), 9 /* TEXT, PROPS */, _hoisted_18)
                    ])
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            ($setup.confirmation_requested && !$setup.trial)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                  _createElementVNode("label", _hoisted_20, [
                    _createElementVNode("span", _hoisted_21, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        id: "cart_clearance",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.cart_clearance) = $event))
                      }, null, 512 /* NEED_PATCH */), [
                        [_vModelCheckbox, $setup.cart_clearance]
                      ])
                    ]),
                    _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.__("I am a person authorized to commit the company for this type of order")), 1 /* TEXT */)
                  ])
                ]))
              : _createCommentVNode("v-if", true),
            ($setup.confirmation_requested && $setup.trial)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                  _createElementVNode("label", _hoisted_24, [
                    _createElementVNode("span", _hoisted_25, [
                      _withDirectives(_createElementVNode("input", {
                        type: "checkbox",
                        id: "trial_terms",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($setup.trial_terms) = $event))
                      }, null, 512 /* NEED_PATCH */), [
                        [_vModelCheckbox, $setup.trial_terms]
                      ])
                    ]),
                    _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.__("I have read and by checking this box I accept the terms of use of the ioi software range")), 1 /* TEXT */)
                  ]),
                  _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.__("Products not available in evaluation will be deleted or replaced")), 1 /* TEXT */)
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_28, [
              (!$setup.trial && $setup.confirmation_requested && ($props.subscription_object.vat_number || $setup.vat_number) && $setup.cart_clearance && $setup.accept_terms)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    class: "btn btn-danger text-right",
                    onClick: _cache[5] || (_cache[5] = $event => ($setup.validate_cart($setup.vat_number, $setup.trial))),
                    disabled: $setup.confirmation_clicked
                  }, _toDisplayString(_ctx.__("Confirm your order")), 9 /* TEXT, PROPS */, _hoisted_29))
                : _createCommentVNode("v-if", true),
              ($setup.trial && $setup.confirmation_requested && $setup.trial_terms)
                ? (_openBlock(), _createElementBlock("button", {
                    key: 1,
                    class: "btn btn-danger text-right",
                    onClick: _cache[6] || (_cache[6] = $event => ($setup.validate_cart($setup.vat_number, $setup.trial))),
                    disabled: $setup.confirmation_clicked
                  }, _toDisplayString(_ctx.__("Confirm your trial request")), 9 /* TEXT, PROPS */, _hoisted_30))
                : _createCommentVNode("v-if", true)
            ])
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}