import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a090611e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "shopping-cart" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "shopping-cart-title" }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "shopping-cart-title" }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { class: "shopping-cart-title" }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { class: "shopping-cart-title" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (Object.keys($setup.cart).length && $setup.cart.subscription_items.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.__($setup.selection_title)), 1 /* TEXT */)
          ]),
          _createElementVNode("div", null, [
            _createVNode($setup["ShoppingCart"], {
              subscription: $setup.cart,
              carttype: "shopping-cart",
              onGet_subscription: _cache[0] || (_cache[0] = $event => ($setup.get_subscription()))
            }, null, 8 /* PROPS */, ["subscription"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (Object.keys($setup.order).length && $setup.order.subscription_items.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.__($setup.waiting_request)), 1 /* TEXT */)
          ]),
          _createElementVNode("div", null, [
            _createVNode($setup["ShoppingCart"], {
              subscription: $setup.order,
              carttype: "order",
              onGet_subscription: _cache[1] || (_cache[1] = $event => ($setup.get_subscription()))
            }, null, 8 /* PROPS */, ["subscription"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (Object.keys($setup.subscription).length && $setup.subscription.subscription_items.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.__($setup.active_subscription)), 1 /* TEXT */)
          ]),
          _createElementVNode("div", null, [
            _createVNode($setup["ShoppingCart"], {
              subscription: $setup.subscription,
              carttype: "subscription",
              onGet_subscription: _cache[2] || (_cache[2] = $event => ($setup.get_subscription()))
            }, null, 8 /* PROPS */, ["subscription"])
          ])
        ]))
      : _createCommentVNode("v-if", true),
    (!Object.keys($setup.subscription).length || (!$setup.subscription.subscription_items.length && !$setup.cart.subscription_items.length && !$setup.order.subscription_items.length))
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("h2", null, _toDisplayString(_ctx.__("Select a product to create a subscription")), 1 /* TEXT */)
          ])
        ]))
      : _createCommentVNode("v-if", true)
  ]))
}